import { PortableText, Link } from '@liftfoils/components'
import { ErrorPageResponse } from '@liftfoils/models'
import {
  getErrorPage,
  getSiteConfiguration,
} from '@liftfoils/services/sanity-service'
import { styled } from '@liftfoils/styles'
import { GetStaticProps, NextPage } from 'next'

const Root = styled('div', {
  $py: 'L',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$gray500_01',
})
const Content = styled('div', {
  gap: '$9',
  textAlign: 'center',
  maxWidth: '900px',
})
const Title = styled('h1', {
  lift_font: 'caps02',
  color: '$gray500',
  mb: '$9',
})
const Description = styled('div', {
  lift_font: 'heading02',
  mb: '$9',
  color: '$gray300',
  '.highlighted': { color: '$gray500' },
  a: {
    textDecoration: 'underline',
  },
})

const Custom404: NextPage<ErrorPageResponse> = ({ pageData }) => {
  return (
    <Root>
      <Content>
        <Title>{pageData.title}</Title>
        <Description>
          <PortableText value={pageData.description} />
        </Description>
        <Link size="medium" appearance="solidGray500" {...pageData.ctaLink}>
          {pageData.ctaLabel}
        </Link>
      </Content>
    </Root>
  )
}

export const getStaticProps: GetStaticProps<ErrorPageResponse> = async ({
  locale,
  preview = false,
}) => {
  const siteConfiguration = await getSiteConfiguration(locale, preview)
  const pageData = await getErrorPage(locale, preview, 'notFound')
  return {
    props: {
      siteConfiguration,
      pageData,
      showFooter: true,
    },
    revalidate: 300,
  }
}

export default Custom404
